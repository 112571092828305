import React, { useEffect, useState, useRef } from 'react'
import { Link, navigate } from 'gatsby'
import HeadlineColumn from './headline-column'
import CloseButton from './close-button'
import AnimateHeight from 'react-animate-height'
import { forEach, initial } from 'lodash'
import { isBrowser } from '../lib/helpers'

const axios = require('axios')
const endpoints = {
  contact: '/.netlify/functions/sendLicensingEmail',
}

var Scroll = require('react-scroll')
const scroll = Scroll.animateScroll
const trackInputPrefix = 'trackInputPrefix-'

const InputWithHint = props => {
  const {
    artistTitle,
    artistName,
    trackTitle,
    trackName,
    tracks,
    onClickRemove,
    animateOnMount,
    initialState,
    group,
    trackNo,
  } = props
  const trackInputRef = useRef()
  const inputRef = useRef()
  const optionsRef = useRef()
  const [value, setValue] = React.useState(initialState[trackInputPrefix + artistName] || '')
  const [isFocused, setIsFocused] = React.useState(false)
  const [animating, setAnimating] = React.useState(animateOnMount)
  const options = props.options || []

  useEffect(() => {
    trackInputRef.current.value = initialState[trackInputPrefix + trackName] || ''
    if (!animating) {
      setAnimating(true)
    }
  }, [])

  useEffect(() => {
    inputRef.current.value = value
  }, [value])

  const handleOnBlur = el => {
    if (!el || optionsRef.current === null || !optionsRef.current.contains(el.relatedTarget)) {
      setIsFocused(false)
    }
  }

  const filteredOptions = options.filter(
    option =>
      option.toLowerCase().includes(value.toLowerCase()) &&
      option.toLowerCase() !== value.toLowerCase()
  )

  return (
    <AnimateHeight
      className="track-input-container"
      data-ref="track-input-container"
      height={animating ? 'auto' : 0}
      onAnimationEnd={() => {
        if (!animating) {
          initialState[trackInputPrefix + trackName] = undefined
          initialState[trackInputPrefix + artistName] = undefined
          onClickRemove()
        }
      }}
    >
      <div className="track-input__first-line">
        <div data-ref={`${trackInputPrefix}${trackName}`} data-track="true">
          <label
            className="track-input__track-name text-xs text-caps mob-pl-1 mob-pt-1"
            htmlFor={trackName}
          >
            {trackTitle}
          </label>
          <input
            ref={trackInputRef}
            className="track-input__track-name text-m-plus mob-pl-1"
            name={`${trackInputPrefix}${trackName}`}
            type="text"
            placeholder={trackTitle}
            title={trackTitle}
            data-type="trackName"
            data-group={group}
            data-track-no={trackNo}
          />
        </div>
        {tracks.length > 1 && (
          <div
            className="track-input__remove"
            onClick={() => {
              setAnimating(false)
            }}
            role="button"
            aria-label="remove song"
          >
            <CloseButton></CloseButton>
          </div>
        )}
      </div>
      <div
        className="licensing-form__track-input"
        data-ref={`${trackInputPrefix}${artistName}`}
        data-track="true"
      >
        <label className="text-xs text-caps mob-pl-1 mob-pr-1 mob-pt-0_3" htmlFor={artistName}>
          {artistTitle}
        </label>
        <input
          ref={inputRef}
          className="text-m-plus mob-p-1 mob-pt-0-i"
          type="text"
          autoComplete="off"
          placeholder={artistTitle}
          name={`${trackInputPrefix}${artistName}`}
          onFocus={() => setIsFocused(true)}
          onBlur={handleOnBlur}
          onChange={el => setValue(el.target.value)}
          title={artistTitle}
          data-type="artistName"
          data-group={group}
          data-track-no={trackNo}
        />
        {isFocused && filteredOptions.length > 0 && (
          <div className="track-input__hint-wrapper">
            <ul
              onBlur={handleOnBlur}
              ref={optionsRef}
              className="track-input__hint mob-pr-1 mob-pb-1 mob-pl-1"
              aria-label="Hint, Warp's artists"
            >
              {filteredOptions.map((option, index) => {
                const left = option.toLowerCase().indexOf(value.toLowerCase())
                let content
                if (value.length > 0 && left >= 0) {
                  content = (
                    <>
                      <span className="not-matched">{option.substring(0, left)}</span>
                      <span>{option.substring(left, left + value.length)}</span>
                      <span className="not-matched">
                        {option.substring(left + value.length, option.length)}
                      </span>
                    </>
                  )
                } else {
                  content = option
                }
                return (
                  <li
                    key={index}
                    tabIndex={0}
                    onClick={el => setValue(el.currentTarget.textContent)}
                    onKeyDown={el => {
                      if (el.key === 'Enter') {
                        setValue(el.currentTarget.textContent)
                      }
                    }}
                  >
                    {content}
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </div>
    </AnimateHeight>
  )
}

const TrackInput = props => {
  const { initialState, group } = props
  const [mounted, setMounted] = React.useState(false)

  let initialStateTrackInputs = Object.keys(initialState).reduce((acc, curVal) => {
    if (curVal.startsWith(trackInputPrefix)) {
      const index = parseInt(curVal.match(/\d+$/)[0])
      if (!isNaN(index)) {
        acc.push(index)
      }
    }
    return acc
  }, [])

  initialStateTrackInputs = [...new Set(initialStateTrackInputs)] || [1]

  const [tracks, setTracks] = React.useState(initialStateTrackInputs)
  const handleOnClickAdd = () => {
    setTracks([...tracks, (Math.max(...tracks) | 0) + 1])
  }

  const handleOnClickRemove = trackIndexToRemove => {
    setTracks(tracks.filter(trackIndex => trackIndex !== trackIndexToRemove))
  }

  useEffect(() => {
    setMounted(true)
    return () => {
      setMounted(false)
    }
  }, [])

  return (
    <>
      {tracks.map((trackIndex, index) => {
        return (
          <div className="track-input" key={trackIndex}>
            <InputWithHint
              index={index}
              tracks={tracks}
              options={props.options}
              artistTitle={`${index + 1} - ${props.field.artistTitle}`}
              artistName={`${props.field.artistName}-${index}`}
              trackTitle={`${index + 1} - ${props.field.songTitle}`}
              trackName={`${props.field.songName}-${index}`}
              animateOnMount={!mounted}
              initialState={initialState}
              onClickRemove={() => {
                handleOnClickRemove(trackIndex)
              }}
              group={group}
              trackNo={index + 1}
            />
          </div>
        )
      })}
      <div
        className="track-input__button mob-p-1 text-m text-caps"
        onClick={handleOnClickAdd}
        role="button"
      >
        Add Track
      </div>
    </>
  )
}

const Input = props => {
  const { type, name, title, inputAdditional, kwargs, initialState, group } = props
  const inputRef = useRef()

  useEffect(() => {
    inputRef.current.value = initialState[name] || ''
    if (kwargs.defaultValue) {
      inputRef.current.value = kwargs.defaultValue
    }
  }, [])

  return (
    <>
      <label className="text-xs text-caps mob-p-1 mob-pb-0-i" htmlFor={name}>
        {title}
      </label>
      <input
        ref={inputRef}
        className="text-m-plus mob-p-1 mob-pt-0-i"
        title={title}
        name={name}
        type={type}
        placeholder={title}
        data-group={group}
        {...inputAdditional}
      />
    </>
  )
}

const LicensingForm = props => {
  const [message, setMessage] = useState(null)
  const [currentMessageHeight, setCurrentMessageHeight] = useState(0)
  const { artists, title, columns, faqRoute } = props
  const formRef = useRef()
  const messageRef = useRef()
  const localStorageKey = 'licensingForm'
  const groups = columns.map(column => column.leftColumn.title)
  let initialState = {}

  useEffect(() => {
    setCurrentMessageHeight(messageRef.current.clientHeight)
  }, [message])

  if (isBrowser()) {
    if (localStorage[localStorageKey]) {
      try {
        initialState = JSON.parse(localStorage[localStorageKey])
      } catch (e) {
        localStorage[localStorageKey] = ''
        return
      }
    }
  }

  const saveInputsIntoLocalStorage = () => {
    const inputs = formRef.current.querySelectorAll('input')
    let collector = {}
    inputs.forEach(input => {
      if (input.type !== 'date' && input.value) {
        collector[input.name] = input.value
      }
    })
    let trackInputs = Object.keys(collector).filter(item => item.startsWith(trackInputPrefix))
    localStorage[localStorageKey] = JSON.stringify(collector)
  }

  useEffect(() => {
    window.addEventListener('unload', saveInputsIntoLocalStorage)
    return () => {
      saveInputsIntoLocalStorage()
      window.removeEventListener('unload', saveInputsIntoLocalStorage)
    }
  }, [])

  const renderSwitch = (field, group) => {
    switch (field._type) {
      case 'formInput':
        let kwargs = {}
        let inputAdditional = {}
        if (field.type === 'date') {
          inputAdditional.max = '2195-06-06'
          const today = new Date()
          const a = 'this is my key'
          let value = {
            a: 'b',
          }

          let values = [
            today.getFullYear().toString(),
            ('0' + (today.getMonth() + 1)).slice(-2),
            ('0' + today.getDate()).slice(-2),
          ]
          kwargs.defaultValue = values.join('-')
        }
        return (
          <div className="form-input input-padding" data-ref={field.name}>
            <Input
              {...field}
              inputAdditional={inputAdditional}
              kwargs={kwargs}
              initialState={initialState}
              group={group}
            />
          </div>
        )
      case 'formTrackInput':
        return (
          <TrackInput
            options={artists}
            field={field}
            initialState={initialState}
            group={group}
          ></TrackInput>
        )
    }
  }

  const handleSuccess = () => {
    setMessage({
      content: 'Success! Thanks for submitting your form.',
      type: 'positive',
    })
  }

  const handleError = message => {
    console.log('error')
    setMessage({
      content: message,
      type: 'negative',
    })
  }

  const handleSubmit = e => {
    const inputs = Array.from(formRef.current.querySelectorAll('input')).map(el => {
      if (el.getAttribute('data-track-no') !== null) {
        const trackNo = el.getAttribute('data-track-no')
        // const shortTitle = el.getAttribute('data-type') === 'artistName' ? `Artist` : `Track`
        const input = el.getAttribute('data-type') === 'artistName' ? `${el.value} \n` : el.value
        return {
          key: el.name,
          value: {
            title: el.title,
            input: input,
            group: el.getAttribute('data-group'),
            trackNo,
          },
        }
      } else {
        return {
          key: el.name,
          value: {
            title: el.title,
            input: el.value,
            group: el.getAttribute('data-group'),
          },
        }
      }
    })

    // console.log(removeDuplicates(inputs, value.title/))

    // check if all inputs are filled
    const missingInputs = inputs.filter(
      input => !input.value.input.optional && !input.value.input.length
    )
    // add background color to missing inputs
    if (missingInputs.length) {
      missingInputs.forEach(input => {
        if (input.value.isTrack) {
          const inputEl = formRef.current.querySelector(`[data-ref="${input.key}"]`)
          const containerEl = inputEl.closest(`[data-ref="track-input-container"]`)
          containerEl.classList.add('input-missing-bg')
        } else {
          const inputEl = formRef.current.querySelector(`[data-ref="${input.key}"]`)
          inputEl.classList.add('input-missing-bg')
        }
      })
      let message = `Please fill in all required fields`
      handleError(message)
      e.preventDefault()
    } else {
      // prepare data for submission
      const data = Object.assign({}, ...inputs.map(object => ({ [object.key]: object.value })))
      const groupedDataArr = groups.map(group => {
        const filtered = Object.entries(data).filter(item => item[1].group === group)
        const groupValue = Object.assign({}, Object.fromEntries(filtered))
        return {
          [group]: groupValue,
        }
      })
      const groupedData = Object.assign({}, ...groupedDataArr)
      // send form to server
      axios
        .post(endpoints.contact, JSON.stringify(groupedData))
        .then(response => {
          if (response.status === 200) {
            handleSuccess()
          }
        })
        .catch(error => {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data)
            console.log(error.response.headers)
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log(error.message)
          }
          let message = 'An error has occurred'
          handleError(message)
        })
      e.preventDefault()
    }
  }

  return (
    <>
      <CloseButton
        className="licensing-form-close-button mob-m-1-i"
        onClick={() =>
          window.previousPath === undefined || window.previousPath.length === 0
            ? (window.location.href = '/')
            : navigate(-1)
        }
      ></CloseButton>
      <div className="licensing-form no-border-top">
        <div className="licensing-form__title">
          <h1 className="arrowDownRight--left text-l text-caps mob-ml-1 mob-mb-0_2">{title}</h1>
        </div>
        <form ref={formRef} onSubmit={handleSubmit}>
          <div className="licensing-form__inputs">
            {columns.map((column, index) => {
              const group = column.leftColumn.title
              return (
                <div key={index} className="columns">
                  <HeadlineColumn>
                    <div className="column">
                      <h2 className="mob-p-1 text-m-plus">{column.leftColumn.title}</h2>
                    </div>
                  </HeadlineColumn>
                  <div className="licensing-form__input-column column is-half">
                    {column.rightColumn.map((field, index) => (
                      <React.Fragment key={index}>{renderSwitch(field, group)}</React.Fragment>
                    ))}
                  </div>
                </div>
              )
            })}
          </div>
          <div ref={messageRef} className={`form-message ${message?.type}`}>
            <AnimateHeight
              duration={500}
              height={message ? 'auto' : 0}
              easing="ease-in"
              onAnimationStart={e =>
                scroll.scrollMore(e.newHeight - currentMessageHeight, {
                  duration: 500,
                  smooth: 'ease-in',
                })
              }
            >
              <div className="mob-p-1">{message?.content}</div>
            </AnimateHeight>
          </div>
          <div className="licensing-form__cta-bar columns">
            <Link to={`/${faqRoute.slug.current}`} className="column is-half text-nav mob-p-1-i">
              FAQ
            </Link>
            <button className="column text-nav is-half" type="submit" onClick={handleSubmit}>
              submit
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default LicensingForm
